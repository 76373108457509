import { useMutation, useQuery } from '@apollo/client';
import { useAuthorization } from '@src/authentication/AuthorizationProvider';
import { WarningMessage } from '@src/design-system/warning-message/WarningMessage';
import {
  Departure,
  FxRates,
  ItineraryWithPromotionalDiscountsDocument,
  Scalars,
  UpdatePromotionalDiscountsDocument,
  UserRole,
} from '@flashpack/graphql';
import { FinanceEntity } from '@src/shared/finance/utils';
import Table, {
  TableData,
  TableRow,
  TableRowContextOption,
} from '@src/shared/table/Table';
import {
  departuresToTableRows,
  pasteFromAbove,
  showTableOpenMenuButton,
  lockPricesAndDiscountsFactory,
  lockedMultiCurrencyHeaders,
} from '@src/shared/table/utils';
import { useToast } from '@src/shared/toast/useToast';
import { useRequiredParams } from '@src/shared/useRequiredParams';
import { ReactNode, useMemo, useState } from 'react';
import { useSafeMutation } from '@src/shared/useSafeMutation';
import { BulkUpdatePromotionalDiscountsModal } from './BulkUpdatePromotionalDiscountModal';
import { useFxRatesWithDecimalsQuery } from '@src/finance/useFxRatesWithDecimalsQuery';
import { handleAmountUsdChangedForLockedMultiCurrencyRow } from './utils';
import { Typography } from 'design-system';

const defaultDepartures: Departure[] = [];

const lockedPricesMessage =
  'The promotional discounts table is read-only because you do not have permission to edit.';

export const PromotionalDiscountsTable = ({
  renderCanton,
}: {
  renderCanton: (hasPendingChanges: boolean) => ReactNode;
}) => {
  const [openBulkUpdateModal, setOpenBulkUpdateModal] = useState<boolean>(false);
  const [selectedDepartureIds, setSelectedDepartureIds] = useState<
    Array<Scalars['UUID']>
  >([]);
  const { error: errorToast, success: successToast } = useToast();
  const { safeMutation } = useSafeMutation();
  const { itineraryId } = useRequiredParams(['itineraryId']);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const fx = useFxRatesWithDecimalsQuery();
  const itinerary = useQuery(ItineraryWithPromotionalDiscountsDocument, {
    variables: {
      id: itineraryId,
    },
  });

  const { currentUser } = useAuthorization();
  const userIsSuperuser = currentUser?.role === UserRole.Superuser;
  const [updatePromotionalDiscounts] = useMutation(UpdatePromotionalDiscountsDocument);

  const rowOptions: TableRowContextOption[] = [pasteFromAbove];

  const departures = itinerary.data?.itinerary?.departures ?? defaultDepartures;

  // Fetch departure promotional discounts.
  const pricingData: TableData = useMemo(() => {
    return {
      headers: lockedMultiCurrencyHeaders,
      rows: departuresToTableRows(
        departures,
        lockPricesAndDiscountsFactory(currentUser?.role),
      ),
    };
  }, [departures, currentUser]);

  if (fx.error) {
    return <Typography variant="pageHeader">Error fetching FX rates</Typography>;
  }

  const handleSubmitDiscountAmounts = async (formValues: TableData) => {
    setIsSubmitting(true);
    successToast('Promotional discounts updated successfully');
    await safeMutation(
      updatePromotionalDiscounts({
        variables: {
          input: formValues.rows
            .filter((row) => row.values.amountUSD != undefined && !row.locked)
            // Filter out rows that don't have a change
            .filter(
              (row) =>
                row.values.amountUSD !==
                departures.find((d) => d.id === row.name)?.promotionalDiscount?.amountUSD,
            )
            .map((row) => {
              return {
                departureId: row.name,
                amountUSD: row.values.amountUSD as number,
              };
            }),
        },
        refetchQueries: [ItineraryWithPromotionalDiscountsDocument],
      }),
      {
        onSuccess: () => {
          setIsSubmitting(false);
          successToast('Promotional discounts updated successfully');
        },
        onError: () => {
          setIsSubmitting(false);
          errorToast('Could not update promotional discounts');
        },
      },
    );
  };

  // Handle query FX rates
  const handleRowChanged = (row: TableRow) => {
    const pricingRate = fx.data?.fxRates.pricing;
    return handleAmountUsdChangedForLockedMultiCurrencyRow(row, pricingRate as FxRates);
  };

  // Render table modelling.
  return (
    <>
      {!userIsSuperuser && (
        <WarningMessage message={lockedPricesMessage} iconType="LOCKED" />
      )}
      <BulkUpdatePromotionalDiscountsModal
        open={openBulkUpdateModal}
        onClose={() => setOpenBulkUpdateModal(false)}
        selectedDepartureIds={selectedDepartureIds}
        setSelectedDepartureIds={setSelectedDepartureIds}
      />
      <Table
        initialData={pricingData}
        handleSubmit={handleSubmitDiscountAmounts}
        rowOptions={rowOptions}
        handleRowChanged={handleRowChanged}
        editable={true}
        isLoading={isSubmitting || fx.loading || itinerary.loading}
        isRequired={false}
        renderCanton={renderCanton}
        showOpenMenuButton={showTableOpenMenuButton}
        submitLabel={'Save Discounts'}
        tableFormId="promotional-discounts-table"
        entity={FinanceEntity.PROMOTIONAL_DISCOUNT}
        selectedRowIds={selectedDepartureIds}
        setSelectedRowIds={setSelectedDepartureIds}
        setOpenBulkUpdateModal={setOpenBulkUpdateModal}
      />
    </>
  );
};
