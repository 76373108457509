import { Button, Stack, Typography, styled } from '@mui/material';
import { useCallback, useContext } from 'react';
import { DaySelector } from 'src/design-system/forms/day-selector/DaySelector';
import { composeValidators, Validator } from 'src/design-system/forms/validators';
import { TimeField } from 'src/design-system/forms/time-field/TimeField';
import { FreeTextFormField, GenericError, CheckboxFormField, Box } from 'design-system';
import { hasServerValidationErrors } from 'src/shared/errorUtils';
import {
  engagementStartDayValidator,
  engagementStartTimeValidator,
  engagementEndDayValidator,
  engagementEndTimeValidator,
} from 'src/itinerary/common/engagementFormValidators';
import { ApolloError } from '@apollo/client';
import { Challenge, UserRole } from '@flashpack/graphql';
import set from 'lodash/set';
import { FormState, ValidationErrors } from 'final-form';
import { Field, Form, FormSpy } from 'react-final-form';
import { SharedTabsContext } from '@src/shared/context/SharedTabsContext';
import { AutocompleteField } from '@src/design-system/forms/autocomplete/AutocompleteField';
import { challengeMap } from '../common/challengeMap';
import { LoadingButton } from '@mui/lab';
import { FormControlLabel } from '@src/shared/FormControlLabel';
import { DayTime } from '@src/shared/timeline/bulk-timeline-items/utils';
import { DeleteEngagements } from '@src/itinerary/common/DeleteEngagements';
import { useRouting } from '@src/shared/useRouting';
import { Protected } from '@src/authentication/Protected';

export interface CreateActivityFormValues {
  title: string;
  start: {
    time: DayTime;
    location: string;
  };
  end: {
    time: DayTime;
    location?: string;
  };
  activity: {
    description?: string | null;
    foodAndDrink?: string | null;
    challenges?: Challenge[] | null | 'Multiple';
    mealIncluded: boolean | 'Multiple';
    websiteDescription?: string;
    includedOnWebsiteItinerary?: boolean;
  } | null;
}

export type EditActivityFormValues = CreateActivityFormValues & {
  ids: string[];
};

interface PropTypes<T> {
  initialValues?: T;
  saveBtnLabel?: string;
  onSubmit: (values: T) => Promise<void>;
  onCancel: () => void;
  error?: ApolloError;
  onChange?: (values: FormState<T>) => void;
  locked?: boolean;
}
type NonUndefined<T> = T extends undefined ? never : T;
type ValidatorFunction<T> = (values: T) => ValidationErrors;

export const ViewEditActivityForm = <
  T extends CreateActivityFormValues | EditActivityFormValues,
>(
  props: PropTypes<T>,
) => {
  const { onSubmit, onCancel, initialValues, saveBtnLabel, error, onChange, locked } =
    props;

  const { activityForm } = useContext(SharedTabsContext);
  const { navigate } = useRouting();

  const validate = useCallback<ValidatorFunction<T>>(({ start, end }) => {
    const validators = {
      'start.time.time': engagementStartTimeValidator,
      'end.time.time': engagementEndTimeValidator,
      'start.time.day': engagementStartDayValidator,
      'end.time.day': engagementEndDayValidator,
    };

    // validates the start and end times of an engagement.
    // The validation errors are accumulated and returned in an object, where each key is the
    // path of the field that failed validation, and the value is the result of the validation
    // function for that field.
    return Object.entries(validators).reduce((errors, [path, validator]) => {
      return set(
        errors,
        path,
        validator(null, {
          start: start.time ?? {},
          end: end.time ?? {},
        }),
      );
    }, {} as NonUndefined<ValidationErrors>);
  }, []);

  if (error && !hasServerValidationErrors(error)) {
    return <GenericError error={error} />;
  }

  return (
    <Form<T>
      validate={validate}
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit, valid, values, submitting, form }) => (
        <form onSubmit={(v) => void handleSubmit(v)}>
          <Stack direction="column" gap={2}>
            <Stack direction="column" gap={2} sx={{ mt: 2 }}>
              <StyledHeader variant="bodySingle">Activity Title</StyledHeader>
              <FreeTextFormField
                name="title"
                placeholder="E.g Hozuagawa boat tour"
                testid="activity-title"
                readonly={locked}
                fieldProps={{
                  validate: Validator.required,
                }}
              />
              <Protected roles={[UserRole.Flashpack]}>
                <Stack marginY={2} gap={1}>
                  <FormControlLabel
                    label="Show as activity on website inclusion list"
                    checked={!!values.activity?.includedOnWebsiteItinerary}
                    control={
                      <Field
                        name="activity.includedOnWebsiteItinerary"
                        type="checkbox"
                        component={CheckboxFormField}
                        size="small"
                      />
                    }
                  />
                  {values.activity?.includedOnWebsiteItinerary && (
                    <>
                      <StyledHeader variant="bodySingle">
                        Marketing Title (optional)
                      </StyledHeader>

                      <FreeTextFormField
                        name="activity.websiteDescription"
                        placeholder="If necessary, use this field to override activity title"
                        testid="activity-marketing-name"
                        variant="outlined"
                        inputProps={{ 'data-testid': 'activity-marketing-name' }}
                        helperText="Only editable by FlashPack employees."
                      />
                    </>
                  )}
                </Stack>
              </Protected>
            </Stack>
            <Stack direction="column" gap={1}>
              <StyledHeader variant="bodySingle" sx={{ mb: 1 }}>
                Start
              </StyledHeader>
              <Stack direction="row" gap={1} data-testid="activity-start">
                <DaySelector
                  name="start.time.day"
                  data-testid="day-selector"
                  fieldProps={{
                    validate: composeValidators<number>(
                      Validator.required,
                      engagementStartDayValidator,
                    ),
                  }}
                  readOnly={locked}
                />
                <TimeField
                  name="start.time.time"
                  label="Time (24hr)"
                  data-testid="time-picker"
                  validate={engagementStartTimeValidator}
                  readonly={locked}
                />
              </Stack>

              <FreeTextFormField
                name="start.location"
                placeholder="Start Location"
                testid="activity-start-location"
                fieldProps={{
                  validate: Validator.required,
                }}
                readonly={locked}
              />
            </Stack>

            <Stack direction="column" gap={1}>
              <StyledHeader variant="bodySingle" sx={{ mb: 1 }}>
                Finish
              </StyledHeader>
              <Stack direction="row" gap={1} data-testid="activity-end">
                <DaySelector
                  name="end.time.day"
                  linkedStartDayField="start.time.day"
                  data-testid="day-selector"
                  label="Select day"
                  fieldProps={{
                    validate: composeValidators<number>(
                      Validator.required,
                      engagementEndDayValidator,
                    ),
                  }}
                  readonly={locked}
                />
                <TimeField
                  name="end.time.time"
                  label="Time (24hr)"
                  data-testid="time-picker"
                  validate={engagementEndTimeValidator}
                  readonly={locked}
                />
              </Stack>
              <FreeTextFormField
                name="end.location"
                testid="activity-end-location"
                placeholder="End Location (leave blank if same as Start)"
                fieldProps={{
                  parse: (value: string) => (value === '' ? null : value),
                }}
                readonly={locked}
              />
            </Stack>

            <Stack direction="column" gap={1}>
              <StyledHeader variant="bodySingle">Activity description</StyledHeader>
              <FreeTextFormField
                name="activity.description"
                placeholder="Optional"
                testid="activity-description"
                multiline
                rows={5}
                fieldProps={{
                  parse: (value: string) => (value === '' ? null : value),
                }}
                readonly={locked}
              />
            </Stack>
            <AutocompleteField<{ value: string }, true, true, false>
              name="activity.challenges"
              label="Challenges"
              options={challengesOptions}
              data-testid="activity-challenges"
              helperText=" "
              getOptionValue={(option) => option.value}
              multiple
              fullWidth
              readOnly={locked}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              getOptionLabel={(option) => {
                return (
                  challengesOptions.find((item) => item.value === option.value)?.label ||
                  ''
                );
              }}
              value={createMappedChallengeValues(values.activity?.challenges)}
            />
            <Stack direction="column" gap={1}>
              <Stack direction="row" justifyContent="space-between">
                <StyledHeader variant="bodySingle">Food & Drink description</StyledHeader>
                <FormControlLabel
                  label="Meal included"
                  checked={!!values.activity?.mealIncluded}
                  control={
                    <Field
                      name="activity.mealIncluded"
                      type="checkbox"
                      component={CheckboxFormField}
                      size="small"
                      disabled={locked}
                    />
                  }
                />
              </Stack>
              <FreeTextFormField
                name="activity.foodAndDrink"
                placeholder="Optional"
                testid="activity-food-drink"
                multiline
                rows={5}
                fieldProps={{
                  parse: (value: string) => (value === '' ? null : value),
                }}
                readonly={locked}
              />
            </Stack>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            mt={5}
            mb={7}
            alignItems="center"
          >
            {!locked && 'ids' in values ? (
              <DeleteEngagements
                engagementName="activity"
                engagementIds={values.ids}
                actionOnDelete={() => navigate('../')}
              />
            ) : (
              <Box />
            )}
            <Stack direction="row" justifyContent="flex-end" gap={1}>
              <Button variant="outlined" onClick={onCancel}>
                Cancel
              </Button>
              <LoadingButton
                variant="contained"
                color="primary"
                data-testid="save-activity"
                type="button" // this is a button to avoid accidental form submission on enter
                onClick={() => {
                  void form.submit();
                }}
                loading={submitting}
                disabled={
                  (valid && !!activityForm && !activityForm?.hasPendingChanges) ||
                  submitting
                }
              >
                {saveBtnLabel}
              </LoadingButton>
            </Stack>
          </Stack>
          <FormSpy
            subscription={{ values: true }}
            onChange={(props: FormState<T, Partial<T>>) => {
              if (onChange) {
                onChange(props);
              }
            }}
          />
        </form>
      )}
    ></Form>
  );
};

const StyledHeader = styled(Typography)(({ theme }) => ({
  color: theme.palette.principal.grey70,
}));

ViewEditActivityForm.defaultProps = {
  saveBtnLabel: 'Save Changes',
  initialValues: {
    start: {},
    end: {},
    activity: {},
  },
};

export const challengesOptions = Object.entries(challengeMap).map(
  ([value, { label }]) => ({
    value,
    label,
  }),
);

export const createMappedChallengeValues = (values?: string[] | null | string) => {
  if (!values || typeof values === 'string') {
    return undefined;
  }
  return values.map((value) => ({
    value,
  }));
};
