import { useMutation, useQuery } from '@apollo/client';
import {
  Departure,
  ItineraryWithPassengerCostsDocument,
  PassengerCostAmountsInput,
  Scalars,
  UpdatePassengerCostsDocument,
} from '@flashpack/graphql';
import { FinanceEntity } from '@src/shared/finance/utils';
import Table, { TableData, TableRowContextOption } from '@src/shared/table/Table';
import {
  departuresToTableRows,
  lockCostsFactory,
  pasteFromAbove,
  showTableOpenMenuButton,
} from '@src/shared/table/utils';
import { useRequiredParams } from '@src/shared/useRequiredParams';
import { FC, useMemo, useState } from 'react';
import { useSafeMutation } from '@src/shared/useSafeMutation';
import { useToast } from '@src/shared/toast/useToast';
import { dialogBoxSpecifications, lockIconSpecifications, mapTableRow } from './utils';
import { CostsComponentProps } from './CostsPage';
import { BulkUpdatePassengerCostsModal } from './BulkUpdatePassengerCostsModal';

const defaultDepartures: Departure[] = [];

export const passengerCostTableHeaders = [
  { name: 'amount4Pax', label: '4', locked: false },
  { name: 'amount5Pax', label: '5', locked: false },
  { name: 'amount6Pax', label: '6', locked: false },
  { name: 'amount7Pax', label: '7', locked: false },
  { name: 'amount8Pax', label: '8', locked: false },
  { name: 'amount9Pax', label: '9', locked: false },
  { name: 'amount10Pax', label: '10', locked: false },
  { name: 'amount11Pax', label: '11', locked: false },
  { name: 'amount12Pax', label: '12', locked: false },
  { name: 'amount13Pax', label: '13', locked: false },
  { name: 'amount14Pax', label: '14', locked: false },
  { name: 'amount15Pax', label: '15', locked: false },
  { name: 'amount16Pax', label: '16', locked: false },
  { name: 'amount17Pax', label: '17', locked: false },
  { name: 'amount18Pax', label: '18', locked: false },
];

export const PassengerCostTable: FC<CostsComponentProps> = ({
  renderCanton,
  nonTableScreenHeight,
}) => {
  const { itineraryId } = useRequiredParams(['itineraryId']);
  const { safeMutation } = useSafeMutation();
  const itinerary = useQuery(ItineraryWithPassengerCostsDocument, {
    variables: {
      id: itineraryId,
    },
  });
  const [openBulkUpdateModal, setOpenBulkUpdateModal] = useState<boolean>(false);
  const [selectedDepartureIds, setSelectedDepartureIds] = useState<
    Array<Scalars['UUID']>
  >([]);

  const departures = itinerary.data?.itinerary?.departures ?? defaultDepartures;
  const costCurrency = itinerary.data?.itinerary?.costCurrency;

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [updatePassengerCosts] = useMutation(UpdatePassengerCostsDocument);
  const { error: errorToast, success: successToast } = useToast();

  const costData: TableData = useMemo(() => {
    return {
      headers: passengerCostTableHeaders,
      rows: departuresToTableRows(departures, lockCostsFactory('passengerCostsLocked')),
    };
  }, [departures]);

  const isInvalidRow: (values: Record<string, number | null>) => boolean = (values) => {
    return !values.amount4Pax && Object.values(values).some((value) => value);
  };

  const handleSubmitPassengerCostAmounts = async (formValues: TableData) => {
    if (formValues.rows.some((row) => isInvalidRow(row.values))) {
      errorToast('Must specify amount for 4 passengers if any other amount is specified');
      return;
    }

    const input = formValues.rows
      .filter((row) => row.values.amount4Pax != undefined && !row.locked)
      // Filter out rows that don't have a change
      .filter((row) => {
        const departure = departures.find((d) => d.id === row.name);
        return (
          departure?.passengerCost?.amount4Pax !== row.values.amount4Pax ||
          departure?.passengerCost?.amount5Pax !== row.values.amount5Pax ||
          departure?.passengerCost?.amount6Pax !== row.values.amount6Pax ||
          departure?.passengerCost?.amount7Pax !== row.values.amount7Pax ||
          departure?.passengerCost?.amount8Pax !== row.values.amount8Pax ||
          departure?.passengerCost?.amount9Pax !== row.values.amount9Pax ||
          departure?.passengerCost?.amount10Pax !== row.values.amount10Pax ||
          departure?.passengerCost?.amount11Pax !== row.values.amount11Pax ||
          departure?.passengerCost?.amount12Pax !== row.values.amount12Pax ||
          departure?.passengerCost?.amount13Pax !== row.values.amount13Pax ||
          departure?.passengerCost?.amount14Pax !== row.values.amount14Pax ||
          departure?.passengerCost?.amount15Pax !== row.values.amount15Pax ||
          departure?.passengerCost?.amount16Pax !== row.values.amount16Pax ||
          departure?.passengerCost?.amount17Pax !== row.values.amount17Pax ||
          departure?.passengerCost?.amount18Pax !== row.values.amount18Pax
        );
      })
      .map((row) => {
        const amounts = mapTableRow(formValues, row);
        return {
          departureId: row.name,
          amounts: amounts as PassengerCostAmountsInput,
        };
      });

    setIsSubmitting(true);
    await safeMutation(
      updatePassengerCosts({
        variables: {
          input,
        },
        refetchQueries: [ItineraryWithPassengerCostsDocument],
      }),
      {
        onSuccess: () => {
          setIsSubmitting(false);
          successToast('Passenger costs updated successfully');
        },
        onError: () => {
          setIsSubmitting(false);
          errorToast('Could not update passenger costs');
        },
      },
    );
  };

  const rowOptions: TableRowContextOption[] = [pasteFromAbove];

  return (
    <>
      <BulkUpdatePassengerCostsModal
        open={openBulkUpdateModal}
        onClose={() => setOpenBulkUpdateModal(false)}
        selectedDepartureIds={selectedDepartureIds}
        setSelectedDepartureIds={setSelectedDepartureIds}
        costCurrency={costCurrency}
      />
      <Table
        initialData={costData}
        handleSubmit={handleSubmitPassengerCostAmounts}
        rowOptions={rowOptions}
        editable={true}
        isLoading={isSubmitting || itinerary.loading}
        showOpenMenuButton={showTableOpenMenuButton}
        isRequired={false}
        renderCanton={renderCanton}
        submitLabel={'Save Passenger Costs'}
        tableFormId="passenger-costs-table"
        entity={FinanceEntity.PASSENGER_COST}
        nonTableScreenHeight={nonTableScreenHeight}
        lockIconSpecifications={lockIconSpecifications}
        dialogBoxSpecifications={dialogBoxSpecifications}
        selectedRowIds={selectedDepartureIds}
        setSelectedRowIds={setSelectedDepartureIds}
        setOpenBulkUpdateModal={setOpenBulkUpdateModal}
      />
    </>
  );
};
