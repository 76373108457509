import { useQuery, useMutation } from '@apollo/client';
import { FinanceEntity } from '@src/shared/finance/utils';
import Table, { TableData, TableRowContextOption } from '@src/shared/table/Table';
import {
  departuresToTableRows,
  lockCostsFactory,
  pasteFromAbove,
  showTableOpenMenuButton,
} from '@src/shared/table/utils';
import { useRequiredParams } from '@src/shared/useRequiredParams';
import { FC, useMemo, useState } from 'react';
import { useSafeMutation } from '@src/shared/useSafeMutation';
import { useToast } from '@src/shared/toast/useToast';
import {
  Departure,
  ItineraryWithMandatoryCostsDocument,
  MandatoryCostAmountsInput,
  Scalars,
  UpdateMandatoryCostsDocument,
} from '@flashpack/graphql';
import { dialogBoxSpecifications, lockIconSpecifications, mapTableRow } from './utils';
import { isEmpty } from 'lodash';
import { CostsComponentProps } from './CostsPage';
import { BulkUpdateMandatoryCostsModal } from './BulkUpdateMandatoryCostsModal';

const defaultDepartures: Departure[] = [];

const headers = [
  { name: 'amountInternalFlight', label: 'Internal Flights', locked: false },
  {
    name: 'amountTransfer',
    label: 'Transfers included',
    locked: false,
    hint: '(Per Person)',
  },
  { name: 'amountOther', label: 'Other', locked: false },
];

export const MandatoryCostTable: FC<CostsComponentProps> = ({
  renderCanton,
  nonTableScreenHeight,
}) => {
  const { itineraryId } = useRequiredParams(['itineraryId']);
  const { safeMutation } = useSafeMutation();
  const itinerary = useQuery(ItineraryWithMandatoryCostsDocument, {
    variables: {
      id: itineraryId,
    },
  });
  const [openBulkUpdateModal, setOpenBulkUpdateModal] = useState<boolean>(false);
  const [selectedDepartureIds, setSelectedDepartureIds] = useState<
    Array<Scalars['UUID']>
  >([]);

  const departures = itinerary.data?.itinerary?.departures ?? defaultDepartures;
  const costCurrency = itinerary.data?.itinerary?.costCurrency;
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [updateMandatoryCosts] = useMutation(UpdateMandatoryCostsDocument);
  const { error: errorToast, success: successToast } = useToast();

  const costData: TableData = useMemo(() => {
    return {
      headers,
      rows: departuresToTableRows(departures, lockCostsFactory('mandatoryCostsLocked')),
    };
  }, [departures]);

  const handleSubmitMandatoryCostAmounts = async (formValues: TableData) => {
    setIsSubmitting(true);
    const input = formValues.rows
      .filter((row) => {
        return !row.locked && !isEmpty(row.values);
      })
      // Filter out rows that don't have a change
      .filter((row) => {
        const departure = departures.find((d) => d.id === row.name);
        return (
          departure?.mandatoryCost?.amountInternalFlight !==
            row.values.amountInternalFlight ||
          departure?.mandatoryCost?.amountTransfer !== row.values.amountTransfer ||
          departure?.mandatoryCost?.amountOther !== row.values.amountOther
        );
      })
      .map((row) => {
        const amounts = mapTableRow(formValues, row);
        return {
          departureId: row.name,
          amounts: amounts as MandatoryCostAmountsInput,
        };
      });

    await safeMutation(
      updateMandatoryCosts({
        variables: {
          input,
        },
        refetchQueries: [ItineraryWithMandatoryCostsDocument],
      }),
      {
        onSuccess: () => {
          setIsSubmitting(false);
          successToast('Mandatory costs updated successfully');
        },
        onError: () => {
          setIsSubmitting(false);
          errorToast('Could not update mandatory costs');
        },
      },
    );
  };

  const rowOptions: TableRowContextOption[] = [pasteFromAbove];

  return (
    <>
      <BulkUpdateMandatoryCostsModal
        open={openBulkUpdateModal}
        onClose={() => setOpenBulkUpdateModal(false)}
        selectedDepartureIds={selectedDepartureIds}
        setSelectedDepartureIds={setSelectedDepartureIds}
        costCurrency={costCurrency}
      />
      <Table
        initialData={costData}
        handleSubmit={handleSubmitMandatoryCostAmounts}
        rowOptions={rowOptions}
        editable={true}
        isLoading={isSubmitting || itinerary.loading}
        showOpenMenuButton={showTableOpenMenuButton}
        isRequired={false}
        renderCanton={renderCanton}
        submitLabel={'Save Mandatory Costs'}
        tableFormId="mandatory-costs-table"
        entity={FinanceEntity.MANDATORY_COST}
        nonTableScreenHeight={nonTableScreenHeight}
        cellWidth={155}
        lockIconSpecifications={lockIconSpecifications}
        dialogBoxSpecifications={dialogBoxSpecifications}
        selectedRowIds={selectedDepartureIds}
        setSelectedRowIds={setSelectedDepartureIds}
        setOpenBulkUpdateModal={setOpenBulkUpdateModal}
      />
    </>
  );
};
