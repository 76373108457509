import { Typography, useTheme, Tooltip, Chip } from '@mui/material';
import { flashPackTheme } from '@src/design-system/theme';
import { FC } from 'react';

export type TripFillIndicatorProps = {
  tripFill?: number | null;
  tripCapacity?: number | null;
  minToGuarantee?: number | null;
};

export const bookingsMoreThanMinToGuarantee = (
  tripCapacity?: number | null,
  tripFill?: number | null,
  minToGuarantee?: number | null,
) => {
  if (!tripCapacity) {
    return false;
  }
  if (minToGuarantee === 0) {
    return true;
  }

  // If tripFill is not provided, it is assumed that the 0 bookings are made
  const displayTripFill = tripFill || 0;
  // If minToGuarantee is not provided, it is assumed that the minToGuarantee is the trip capacity
  const displayMinToGuarantee = minToGuarantee || tripCapacity;

  return typeof displayTripFill === 'number' && displayTripFill >= displayMinToGuarantee;
};

export const TripFillIndicator: FC<TripFillIndicatorProps> = ({
  tripFill,
  tripCapacity,
  minToGuarantee,
}) => {
  const theme = useTheme();
  if (!tripCapacity) {
    return <Typography variant="bodySingle">N/A</Typography>;
  }

  const displayTripFill = Number.isInteger(tripFill) ? tripFill : '-';
  const isBookingsMoreThanMinToGuarantee = bookingsMoreThanMinToGuarantee(
    tripCapacity,
    tripFill,
    minToGuarantee,
  );

  const textColor = isBookingsMoreThanMinToGuarantee
    ? flashPackTheme.palette.system.green100
    : flashPackTheme.palette.principal.grey70;

  return (
    <Tooltip title="Trip Fill">
      <Chip
        sx={{
          color: textColor,
          backgroundColor: theme.palette.principal.grey30,
        }}
        label={
          <Typography variant="bodySingle">
            {displayTripFill}/{tripCapacity}
          </Typography>
        }
      />
    </Tooltip>
  );
};
