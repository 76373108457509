import {
  Challenge,
  CreateEngagement,
  EditEngagementsInput,
  EngagementFieldsFragment,
} from '@flashpack/graphql';
import {
  CreateActivityFormValues,
  EditActivityFormValues,
} from '../view-activity/ViewEditActivityForm';
import { getPayloadForEditActivities } from '@src/shared/timeline/bulk-timeline-items/utils';

export const deepStripTypename = <T>(object: T) =>
  JSON.parse(JSON.stringify(object), (key, value) =>
    key === '__typename' ? undefined : (value as unknown),
  ) as Omit<T, '__typename'>;

export const mapFormValuesToCreateActivity = (
  values: CreateActivityFormValues,
): CreateEngagement => {
  return {
    title: values.title,
    start: {
      time: {
        time: values.start.time.time,
        day: values.start.time.day as number,
      },
      location: values.start.location,
    },
    end: {
      time: {
        time: values.end.time.time,
        day: values.end.time.day as number,
      },
      location: values.end.location ?? values.start.location,
    },
    activity: {
      description: values.activity?.description,
      foodAndDrink: values.activity?.foodAndDrink,
      challenges: values.activity?.challenges as Challenge[],
      mealIncluded: values.activity?.mealIncluded as boolean,
    },
  };
};

// TODO: refactor to remove this function and use getPayloadForEditActivities directly
export const mapFormValuesToEditActivityInput = (
  values: EditActivityFormValues,
): EditEngagementsInput => {
  return getPayloadForEditActivities(values);
};

export const toEditFormValues = (
  values: EngagementFieldsFragment,
): EditActivityFormValues => {
  return {
    ids: [values.id],
    title: values.title,
    activity: {
      description: values.activity?.description,
      foodAndDrink: values.activity?.foodAndDrink,
      challenges: values.activity?.challenges,
      mealIncluded: values.activity?.mealIncluded || false,
      websiteDescription: values.activity?.websiteDescription ?? undefined,
      includedOnWebsiteItinerary: values.activity?.includedOnWebsiteItinerary || false,
    },
    start: {
      location: values.start.location,
      time: { day: values.start.time.day, time: values.start.time.time },
    },
    end: {
      location: values.end.location,
      time: { day: values.end.time.day, time: values.end.time.time },
    },
  };
};
